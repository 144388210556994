import React from "react"
import {Layout} from "../components/Layout";
import {LayoutContentArea} from "../components/LayoutContentArea";
import * as styles from "./eduTemplate.module.scss"
import {Article} from "../../EduPagesCreator";
import {siteMetaData} from "../siteMetaData";

export default function Template({pageContext}: { pageContext: { article: Article } }) {
  const html = pageContext.article.html
  const {slug, title} = pageContext.article.frontmatter
  return (
    <Layout
      href={`${siteMetaData.siteUrl}${slug}`}
      title={title}
    >
      <LayoutContentArea>
        <div className="blog-post-container">
          <div className="blog-post">
            <h1>{title}</h1>
            <div
              className={styles.blogPostContent}
              dangerouslySetInnerHTML={{__html: html}}
            />
          </div>
        </div>
      </LayoutContentArea>
    </Layout>
  )
}
